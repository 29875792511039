import styled from '@emotion/styled';
import media from 'utils/mediaqueries'

export const ColumnStyled = styled('div')({
  position: 'relative',
  width: '100%',
  // flexDirection: 'column-reverse',

  [media('desktop')]: {
    flex: '1 1 0',
    width: 'auto',
  },
},
props =>
  props.bg && {
    backgroundColor: props.bg,
  },
props =>
  props.color && {
    color: props.color,
  },
props =>
  props.bigger && {
    [media('desktop')]: {
      flexGrow: 2,
    }
  },
props =>
  props.alignItems && {
    display: 'flex',
    flexDirection: 'column',
  },
props =>
  props.alignItems === 'right' && {
    alignItems: 'flex-end',
  },
props =>
  props.alignItems === 'left' && {
    alignItems: 'flex-start',
  },
props =>
  props.justifyContent && {
    justifyContent: props.justifyContent,
  },
)

export const ContentStyled = styled('div')({
  padding: '20px 20px 90px',
  // minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',

  [media('tabletFluid')]: {
    padding: '50px 90px',
  },

  [media('desktop')]: {
    // padding: '50px 90px',
  },
},
props =>
  props.noPadding && {
    padding: 0,
    [media('tabletFluid')]: {
      padding: 0
    },
  },
props =>
  props.alignItems && {
    width: 'auto',
    display: 'inline-block',
    height: 'auto',
    background: 'white',
    position: 'relative',
    zIndex: 1,
    borderRadius: 0,

    [media('tablet')]: {
      borderRadius: 5,
      boxShadow: '10px 10px 18px rgba(0,0,0,0.05)',
    },
  },
props =>
  props.alignItems === 'left' && {
    [media('tablet')]: {
      transform: 'translateY(-60px)',
      margin: '0 auto',
    },

    [media('desktop')]: {
      transform: 'translateX(-60px)',
      margin: '0',
    },
  },
props =>
  props.alignItems === 'right' && {
    [media('tablet')]: {
      transform: 'translateY(-60px)',
      margin: '0 auto',
    },

    [media('desktop')]: {
      transform: 'translateX(60px)',
      margin: '0',
    },
  },
);
