import React from 'react';
import PropTypes from 'prop-types';

import { ColumnStyled, ContentStyled } from './ColumnStyled.js';

export default function Column({ children, bg, color, noPadding, alignItems, justifyContent, bigger }) {
  return (
    <ColumnStyled bg={bg} color={color} alignItems={alignItems} justifyContent={justifyContent} bigger={bigger}>
      <ContentStyled noPadding={noPadding} alignItems={alignItems}>
        {children}
      </ContentStyled>
    </ColumnStyled>
  );
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  noPadding: PropTypes.bool,
  bigger: PropTypes.bool,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
};

Column.defaultProps = {
  bg: '',
  color: '',
  noPadding: false,
  bigger: false,
  alignItems: '',
  justifyContent: ''
};
